import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItem } from '@services/context.service';
import {
  InspectionFinding,
  InspectionTemplateQuestion,
  InspectionValueValueNumber,
} from '@api-clients/dossier';

@Component({
  selector: 'app-inspection-number-question',
  templateUrl: './number-question.component.html',
  styleUrl: './number-question.component.scss',
})
export class NumberQuestionComponent implements OnInit {
  @Input() question!: InspectionTemplateQuestion | InspectionFinding;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Input() disabled = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  expanded = false;
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: (): boolean => !this.first,
        action: (): void => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: (): boolean => !this.last,
        action: (): void => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: (): void => this.delete.emit(),
      },
    ];
  }

  get value(): number {
    const finding = this.question as InspectionFinding;
    if (finding.value === null || finding.value === undefined) {
      return 0;
    }
    return (finding.value as InspectionValueValueNumber).number;
  }

  set value(newValue: number | string) {
    // Ensure newValue is a valid number or string
    newValue = this.convertToNumber(newValue);
    const finding = this.question as InspectionFinding;
    finding.value = { number: newValue, type: 'Number' };
  }

  sanitizeInput(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    inputValue = inputValue.replace(/,/g, '.');
    inputValue = inputValue.replace(/[^0-9,-]/g, '');

    const commaCount = (inputValue.match(/,/g) || []).length;
    if (commaCount > 1) {
      inputValue = inputValue.replace(/,/g, (match, offset, string) => {
        return string.indexOf(match) === offset ? match : '';
      });
    }

    if (inputValue.includes('-') && inputValue.indexOf('-') > 0) {
      inputValue = inputValue.replace(/-/g, '');
    }

    inputElement.value = inputValue;
  }

  convertToNumber(value: number | string): number {
    const numValue: number = Number(value);
    return isNaN(numValue) ? 0 : numValue;
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  isFinding(): boolean {
    return 'value' in this.question;
  }
}
