import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ViewerComponent } from './components/viewer/viewer.component';
import { BuildingInfoComponent } from './components/viewer/viewer-items/building-info/building-info.component';
import { SearchBarComponent } from './components/viewer/viewer-items/search-bar/search-bar.component';
import { InformationItemComponent } from './components/information-container/information-item/information-item.component';
import { InformationContainerComponent } from './components/information-container/information-container.component';
import { InformationDescriptionComponent } from './components/information-container/information-description/information-description.component';
import { InformationLinkComponent } from './components/information-container/information-link/information-link.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, CommonModule, KeyValuePipe, NgForOf, NgIf } from '@angular/common';
import { CdkFixedSizeVirtualScroll, ScrollingModule } from '@angular/cdk/scrolling';
import { RouterLink, RouterModule } from '@angular/router';
import { InformationFilterComponent } from './components/information-container/information-filter/information-filter.component';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
import { TableFilterComponent } from './components/table-filter/table-filter.component';
import { Map2DComponent } from './components/viewer/map-2d/map-2d.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ResidenceSidebarComponent } from '@shared/components/residence-sidebar/residence-sidebar.component';
import { BigSelectComponent } from '@shared/components/big-select/big-select.component';
import { SelectItemComponent } from '@shared/components/big-select/select-item/select-item.component';
import { LightswitchComponent } from '@shared/components/lightswitch/lightswitch.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { ProfilePictureComponent } from '@shared/components/profile-picture/profile-picture.component';
import { Map3DLayerComponent } from './components/viewer/map-3d/map-3d-layer';
import { AdditionalPropertiesPipe } from './components/viewer/viewer-items/building-info/additional-properties-pipe';
import { HdBuildingManagerComponent } from './components/viewer/map-3d/assets/hd-building-manager';
import { AuthConfigModule } from '../auth/auth-config.module';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ContextMenuComponent } from '@shared/components/context-menu/context-menu.component';
import { ContextMenuDirective } from '@shared/directives/context-menu.directive';
import { ThreeDotComponent } from '@shared/components/three-dot/three-dot.component';
import { BuildingMenuComponent } from '@shared/components/building-menu/building-menu.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from '@shared/interceptors/error-interceptor.service';
import { UnauthorizedInterceptor } from '@shared/interceptors/unauthorized-interceptor.service';
import { PageSliderComponent } from '@shared/components/page-slider/page-slider.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FilePreviewComponent } from '@shared/components/file-preview/file-preview.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FileSpecsComponent } from '@shared/components/file-specs/file-specs.component';
import { CoreModule } from '@baseflow/core';
import { FloorPlanComponent } from '@shared/components/floor-plan/floor-plan.component';
import { ImgFallbackDirective } from '@shared/directives/img-fallback.directive';
import { CdkDrag, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { PointLockControlsComponent } from '@shared/components/threed/scene/point-lock-controls/point-lock-controls.component';
import { SceneComponent } from '@shared/components/threed/scene/scene.component';
import { GrowingTextareaComponent } from '@shared/components/growing-textarea/growing-textarea.component';
import { VisiblePipe } from '@shared/pipes/visible-pipe';
import { StatusBubbleComponent } from '@shared/components/status-bubble/status-bubble.component';

@NgModule({
  declarations: [
    ViewerComponent,
    BuildingInfoComponent,
    SearchBarComponent,
    InformationItemComponent,
    InformationDescriptionComponent,
    InformationContainerComponent,
    InformationLinkComponent,
    InformationFilterComponent,
    MainMenuComponent,
    SidebarComponent,
    BreadcrumbComponent,
    TablePaginationComponent,
    TableFilterComponent,
    Map2DComponent,
    ResidenceSidebarComponent,
    BigSelectComponent,
    SelectItemComponent,
    LightswitchComponent,
    Map3DLayerComponent,
    AdditionalPropertiesPipe,
    HdBuildingManagerComponent,
    ConfirmationDialogComponent,
    ProfilePictureComponent,
    ColorPickerComponent,
    ContextMenuComponent,
    ContextMenuDirective,
    ThreeDotComponent,
    BuildingMenuComponent,
    PageSliderComponent,
    FilePreviewComponent,
    FileSpecsComponent,
    FloorPlanComponent,
    ImgFallbackDirective,
    PointLockControlsComponent,
    SceneComponent,
    GrowingTextareaComponent,
    VisiblePipe,
    StatusBubbleComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    ScrollingModule,
    KeyValuePipe,
    NgIf,
    RouterLink,
    CdkFixedSizeVirtualScroll,
    NgForOf,
    TranslateModule,
    MatTreeModule,
    MatButtonModule,
    MatCheckboxModule,
    AuthConfigModule,
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxDocViewerModule,
    CoreModule,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
  ],
  exports: [
    MatListModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ViewerComponent,
    BuildingInfoComponent,
    MatInputModule,
    Map3DLayerComponent,
    SearchBarComponent,
    InformationItemComponent,
    InformationDescriptionComponent,
    InformationContainerComponent,
    InformationLinkComponent,
    InformationFilterComponent,
    MainMenuComponent,
    SidebarComponent,
    BreadcrumbComponent,
    TablePaginationComponent,
    TableFilterComponent,
    ResidenceSidebarComponent,
    BigSelectComponent,
    SelectItemComponent,
    LightswitchComponent,
    ConfirmationDialogComponent,
    ProfilePictureComponent,
    ColorPickerComponent,
    ContextMenuComponent,
    ContextMenuDirective,
    ThreeDotComponent,
    BuildingMenuComponent,
    PageSliderComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FilePreviewComponent,
    FileSpecsComponent,
    NgxDocViewerModule,
    FloorPlanComponent,
    ImgFallbackDirective,
    PointLockControlsComponent,
    SceneComponent,
    GrowingTextareaComponent,
    StatusBubbleComponent,
  ],
  providers: [
    // Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(@Optional() @SkipSelf() parentModule: SharedModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
