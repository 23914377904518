<div class="bim-overview">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

      <h1 class="h4">{{ 'bim-overview-page' | translate }}</h1>
    </div>

    <div class="header-bar__search">
      <input
        class="top-search"
        type="search"
        [(ngModel)]="searchText"
        (input)="search()"
        placeholder="{{ 'search' | translate }}" />
    </div>
  </div>

  <app-table-filter>
    <div class="select">
      <select [(ngModel)]="selectedBimSource" (change)="load()">
        <option [ngValue]="undefined">{{ 'choose-bim-source' | translate }}</option>
        <option *ngFor="let item of BimSource | keyvalue" [value]="item.value">
          {{ 'source-type.' + item.value | translate }}
        </option>
      </select>
    </div>
    <div class="select">
      <select [(ngModel)]="selectedBimStatus" (change)="load()">
        <option [ngValue]="undefined">{{ 'choose-bim-status' | translate }}</option>
        <option *ngFor="let item of BimStatus | keyvalue" [value]="item.value">
          {{ 'bim-status.' + item.value | translate }}
        </option>
      </select>
    </div>
  </app-table-filter>
  <table class="table responsive-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columns">
          <th (click)="toggleSort(column.key)" *ngIf="column.key !== undefined" class="sortable">
            {{ column.label | translate }}
            <span
              [class.arrow-active]="sortBy === column.key"
              [class.arrow-down]="sortOrder === SortOrder.Asc"
              class="arrow"></span>
          </th>
          <th *ngIf="column.key === undefined">
            {{ column.label | translate }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of filteredData" (click)="openDetail(item)" class="table-link">
        <td [attr.data-title]="'address' | translate">
          {{ item.address ? item.address.place + ', ' : '' }}{{ item.address?.address }}
          {{ item.address?.house_number }}
        </td>
        <td [attr.data-title]="'description' | translate">{{ item.description }}</td>
        <td [attr.data-title]="'status' | translate">
          <app-status-bubble
            [class]="item.status | lowercase"
            [status]="'bim-status.' + item.status | translate"
            [description]="
              item.status === 'Error'
                ? 'bim-status.error-description'
                : item.status === 'OutOfMemory'
                  ? 'bim-status.out-of-memory-description'
                  : ''
            "></app-status-bubble>
        </td>
        <td [attr.data-title]="'source' | translate">
          {{ 'source-type.' + item.source | translate }}
        </td>
        <td [attr.data-title]="'upload-time' | translate">{{ item.upload_time }}</td>
      </tr>
    </tbody>
  </table>

  <div class="pagination">
    <app-table-pagination
      (pageChange)="onPageChange($event)"
      [total]="totalCount"
      [pageSize]="defaultPageSize"
      [page]="1"></app-table-pagination>
  </div>
</div>
