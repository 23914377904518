/**
 * TwinSPOT.Api
 * Users API for TwinSPOT
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Create 1 = Edit 2 = Delete
 */
export type PermissionType = 0 | 1 | 2;

export const PermissionType = {
    NUMBER_0: 0 as PermissionType,
    NUMBER_1: 1 as PermissionType,
    NUMBER_2: 2 as PermissionType
};

